import React, { useEffect, useState } from "react"
import { navigate } from "gatsby"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { useSelector, useDispatch } from "react-redux"
import Box from "@material-ui/core/Box"
import dateFormat from "dateformat"
import Container from "@material-ui/core/Container"
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {
  //   setBasicPumpSizingFromSaved,
  removeQuoteSave,
  getQuoteSaveList,
  getAdminOrdersList,
  getUsers
} from "../../state/store"
import MaterialTable from "material-table"

import {
  Edit,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  Clear,
  ArrowUpward,
  Add,
  Check,
} from "@material-ui/icons"
import AlertDialog from "../dialog"
import { roundCashFormat } from "../../utils/common"

const HistoryList = () => {
  const { userProfile, adminOrders, users } = useSelector(state => state.db)
  const dispatch = useDispatch()
  const [detail, setDetail] = useState(null)

  useEffect(() => {
    if (userProfile.id) {
      dispatch(getAdminOrdersList())
      dispatch(getUsers())

    }
  }, [userProfile])

  return (
    <>
      {detail &&
        <Dialog
          fullWidth
          open={detail}
          onClose={() => setDetail(null)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">Detail</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">


              <MaterialTable
                icons={{
                  Edit,
                  Delete,
                  FirstPage,
                  LastPage,
                  NextPage: ChevronRight,
                  PreviousPage: ChevronLeft,
                  ResetSearch: Clear,
                  Search,
                  SortArrow: ArrowUpward,
                  Add,
                  Check,
                  Clear,
                }}
                columns={[

                  {
                    title: "Name",
                    field: "name",
                  },
                  {
                    title: "Code",
                    field: "code",
                  },
                  {
                    title: "Quantity",
                    field: "quantity",
                  },
                  {
                    title: "Price",
                    field: "price",
                    render: rowData => <span>{roundCashFormat(rowData.price)}</span>,
                  },

                  //   {
                  //     title: "Customer",
                  //     field: "client.customer",
                  //     render: rowData => <span>{rowData.client?.customer}</span>,
                  //   },
                  //   {
                  //     title: "Email",
                  //     field: "client.email",
                  //     render: rowData => <span>{rowData.client?.email}</span>,
                  //   },
                  //   {
                  //     title: "Water Needed",
                  //     field: "pumpValues.waterNeeded",
                  //     searchable: false,
                  //     render: rowData => (
                  //       <span>{rowData.pumpValues.waterNeeded}</span>
                  //     ),
                  //   },
                ]}
                options={{
                  pageSize: 20,
                  actionsColumnIndex: -1,
                }}
                data={JSON.parse(JSON.stringify(detail.rows))}
                title="Order"
              />
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setDetail(null)} color="primary" autoFocus>
              Ok
            </Button>
          </DialogActions>
        </Dialog>}




      <Button variant="contained" color="primary" onClick={() => {
        dispatch(getAdminOrdersList())
        dispatch(getUsers())
      }}>Refresh</Button>

      {adminOrders && userProfile && users && (adminOrders.loading || userProfile.loading || users.loading) && (
        <CircularProgress />
      )}

      <div style={{ overflowX: "auto", marginTop: "10px" }}>
        {users && users.data && adminOrders && adminOrders.data && adminOrders.data.length > 0 && (
          <MaterialTable
            icons={{
              Edit,
              Delete,
              FirstPage,
              LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search,
              SortArrow: ArrowUpward,
              Add,
              Check,
              Clear,
            }}
            columns={[
              {
                title: "Date",
                field: "date",
                searchable: false,
                render: rowData => (
                  <span>
                    {rowData.date &&
                      dateFormat(
                        new Date(rowData.date.seconds * 1000),
                        "dd mmm yyyy HH:MM"
                      )}
                  </span>
                ),
              },
              //   { title: "Type", field: "type", searchable: false },
              {
                title: "Email",
                field: "uid",
                render: rowData => <span>{users.data.find(a => a.id === rowData.uid).email}</span>,
              },
              {
                title: "Phone",
                field: "uid",
                render: rowData => <span>{users.data.find(a => a.id === rowData.uid).phone}</span>,
              },
              {
                title: "Company",
                field: "uid",
                render: rowData => <span>{users.data.find(a => a.id === rowData.uid).companyName}</span>,
              },
              {
                title: "Roles",
                field: "uid",
                render: rowData => <span>{users.data.find(a => a.id === rowData.uid).roles.join(',')}</span>,
              },
              {
                title: "Collect",
                field: "collect",
              },
              {
                title: "Client Ref",
                field: "clientRef",
              },
              {
                title: "Special Instructions",
                field: "specialInstructions",
              },
              {
                title: "Status",
                field: "status",
              },
              //   {
              //     title: "Customer",
              //     field: "client.customer",
              //     render: rowData => <span>{rowData.client?.customer}</span>,
              //   },
              //   {
              //     title: "Email",
              //     field: "client.email",
              //     render: rowData => <span>{rowData.client?.email}</span>,
              //   },
              //   {
              //     title: "Water Needed",
              //     field: "pumpValues.waterNeeded",
              //     searchable: false,
              //     render: rowData => (
              //       <span>{rowData.pumpValues.waterNeeded}</span>
              //     ),
              //   },
            ]}
            actions={[
              () => ({
                icon: Edit,
                tooltip: "Show",
                onClick: (event, rowData) => {
                  setDetail(rowData)
                  // alert(`Details:\n${JSON.stringify(rowData, null, 2)}`)
                },
              }),
              //   () => ({
              //     icon: Delete,
              //     tooltip: "Delete",
              //     onClick: (event, rowData) =>
              //       dispatch(removeQuoteSave(rowData.id)),
              //   }),
            ]}
            options={{
              pageSize: 20,
              actionsColumnIndex: -1,
            }}
            data={JSON.parse(JSON.stringify(adminOrders.data))}
            title="Orders"
          />
        )}
      </div>
    </>
  )
}

const AdminOrders = () => {
  //   const { products } = useSelector(state => state.db)
  return (
    <Container component="main" maxWidth="md">
      <Box mb={2}>
        <Typography component="h1" variant="h4">
          Orders
        </Typography>
      </Box>
      <HistoryList />
    </Container>
  )
}

export default AdminOrders
