import React from "react"
import { Router } from "@reach/router"
import { navigate } from "gatsby"
import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Typography from "@material-ui/core/Typography"
import AdminTowns from "../components/admin/towns"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { useSelector } from "react-redux"
import { canAccessAdmin, canAccessUsers, isRep, canAccessStock, canAccessWarehouse } from "../utils/checkRoles"
import AdminUsers from "../components/admin/users"
import AdminFlows from "../components/admin/flows"
import CircularProgress from "@material-ui/core/CircularProgress"
import AdminDownloads from "../components/admin/downloads"
import AdminAudits from "../components/admin/audits"
import AdminRep from "../components/admin/rep"
import AppWarehouse from "../components/warehouse"
import AdminCalculations from "../components/admin/calculations"
import AppAdminClaims from "../components/warehouse/claims"
import AdminOrders from "../components/orders"
import AdminAlertMessage from "../components/admin/alertMessage"

const AdminMain = () => <>
 <Typography component="h1" variant="h2">
        Admin
      </Typography>
      <Typography component="h2" variant="h5">
        View your admin things here. (See see the menu)
      </Typography>


      <Button variant="contained" color="primary" onClick={() => navigate("/admin/towns/")}>Towns</Button> {" "}
      <Button variant="contained" color="primary" onClick={() => navigate("/admin/users/")}>Users</Button> {" "}


</>

const AdminPage = () => {
  const { userProfile, ready } = useSelector(state => state.db)

  const accessAdmin = ready && userProfile && userProfile.roles && canAccessAdmin(userProfile.roles)
  const accessUsers = ready && userProfile && userProfile.roles && canAccessUsers(userProfile.roles)
  const accessRep = ready && userProfile && userProfile.roles && isRep(userProfile.roles)
  const stockCount = ready && userProfile && userProfile.roles && canAccessStock(userProfile.roles)
  const stockWarehouse = ready && userProfile && userProfile.roles && canAccessWarehouse(userProfile.roles)

  return (
    <Layout>
    <SEO title="Admin" />
    <Container component="main" maxWidth="xl">
      <Router>
        {accessAdmin && <>
          <AdminTowns path="/admin/towns/" />
          <AdminFlows path="/admin/flows/" />
          <AdminDownloads path="/admin/downloads/" />
          <AdminAudits path="/admin/audits/" />
          <AdminMain path="/admin/"/>
          <AdminCalculations path="/admin/calculations"/>
          <AdminAlertMessage path="/admin/alerts"/>
        </>}

        {accessRep && <AdminRep path="/admin/rep/"/>}

        {stockWarehouse && <AppWarehouse path="/admin/warehouse/" />}

        {stockWarehouse && <AppAdminClaims path="/admin/claims/" />}

        {stockWarehouse && <AdminOrders path="/admin/orders/" />}


        {accessUsers && <>
          <AdminMain path="/admin/"/>
          <AdminUsers path="/admin/users/" /> 
          </> }
        {/* <NotFound default /> */}
      </Router>

      {!accessAdmin && !accessUsers && !stockCount && !stockWarehouse && (
        <Typography component="h1" variant="h5">
          You cannot access this admin page
        </Typography>
      )}
    {!ready && <CircularProgress/>}
    </Container>
    </Layout>
  )
}

export default AdminPage
