import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import {
  getDownloads,
  uploadDownloadFile,
  addDownload,
  removeDownload,
  updateDownload,
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import { Formik, Form } from "formik"

import MaterialTable from "material-table"

import {
  AttachFile,
  Edit,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  Clear,
  ArrowUpward,
  Add,
  Check,
} from "@material-ui/icons"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  addButton: {
    margin: theme.spacing(1),
  },
}))

const FileDialog = ({ show, fileId, closeDialog }) => {
  const dispatch = useDispatch()

  const classes = useStyles()
  const handleClose = () => {
    //   setOpen(false);
    closeDialog()
  }

  return (
    <div>
      <Dialog
        open={show ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">
          Add/Replace file to download
        </DialogTitle>
        <DialogContent>
          {fileId && (
            <Formik
              initialValues={{ aFile: "" }}
              onSubmit={(values, actions) => {
                // saveResult(values)
                console.log("submiting", values)
                // dispatch(uploadProductImage('1234', values.file.name, values.file))
                // const {file, ...extra} = values
                if (values.aFile) {
                  //   dispatch(addProduct(values))
                  actions.setSubmitting(false)
                  dispatch(
                    uploadDownloadFile(
                      fileId,
                      "file",
                      values.aFile.name,
                      values.aFile
                    )
                  )
                  handleClose()
                } else {
                  console.log("missing files")
                }
              }}
            >
              {({ errors, setFieldValue, isSubmitting }) => (
                <Form className={classes.form}>
                  <div>
                    <label htmlFor="file">File</label>
                    <input
                      id="file"
                      type="file"
                      name="aFile"
                      // error={
                      //   getIn(errors, field.name) &&
                      //   getIn(touched, field.name)
                      //     ? true
                      //     : false
                      // }
                      // autoFocus
                      // labelWidth={80}
                      // required
                      onChange={event => {
                        setFieldValue("aFile", event.currentTarget.files[0])
                      }}
                    />
                  </div>
                  <br />
                  {console.log("errors", errors)}
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    disabled={isSubmitting || Object.keys(errors).length > 0}
                    type="submit"
                  >
                    Save
                  </Button>
                </Form>
              )}
            </Formik>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const AdminDownloads = () => {
  const [fileUploadId, setFileUploadId] = useState(null)
  const { downloads } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getDownloads())
  }, [])
  return (
    <>
      <Typography component="h1" variant="h5">
        Downloads
      </Typography>
      {downloads && downloads.loading && <CircularProgress />}
      <FileDialog
        show={fileUploadId}
        fileId={fileUploadId}
        closeDialog={() => setFileUploadId(null)}
      />

      <div style={{ overflowX: "auto" }}>
        {downloads && downloads.data && (
          <MaterialTable
            icons={{
              Edit,
              Delete,
              FirstPage,
              LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search,
              SortArrow: ArrowUpward,
              Add,
              Check,
              Clear,
            }}
            editable={{
              onRowAdd: newData =>
                new Promise((resolve) => {
                  console.log("newData", newData)
                  dispatch(addDownload(newData))
                  setTimeout(() => {
                    resolve()
                  }, 1000)
                }),
              onRowUpdate: (newData) =>
                new Promise((resolve) => {
                  const { file, fileUrl, ...normalStuff } = newData
                  dispatch(updateDownload(normalStuff))
                  setTimeout(() => {
                    resolve()
                  }, 1000)
                }),
              onRowDelete: oldData =>
                new Promise((resolve) => {
                  dispatch(removeDownload(oldData))
                  setTimeout(() => {
                    resolve()
                  }, 1000)
                }),
            }}
            columns={[
              { title: "Title", field: "title" },
              { title: "Description", field: "description" },
              {
                title: "File ID",
                field: "file",
                editable: "never",
                //     editComponent: props => (
                //     <input
                //       type="text"
                //       value={props.value}
                //       onChange={e => props.onChange(e.target.value)}
                //     />
                //   )
              },
              { title: "Category", field: "category" },
              { title: "Role", field: "role", lookup: { RESELLER: 'RESELLER', PLATINUMRESELLER: 'PLATINUMRESELLER', GOLDRESELLER: 'GOLDRESELLER', DIAMONDRESELLER: 'DIAMONDRESELLER', NAMIBIA_GOLD: 'NAMIBIA_GOLD', NAMIBIA_RESELLER: "NAMIBIA_RESELLER", ADMIN: 'ADMIN' }, },

            ]}
            actions={[
              {
                icon: AttachFile,
                tooltip: "Add file",
                onClick: (event, rowData) => {
                  // Do save operation
                  console.log("do a file upload??", rowData)
                  setFileUploadId(rowData.id)
                },
              },
            ]}
            data={JSON.parse(JSON.stringify(downloads.data))}
            title="Dowloads List"
            options={{pageSize: 20}}
          />
        )}
      </div>
    </>
  )
}

export default AdminDownloads
