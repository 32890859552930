import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { getAudits } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"

import MaterialTable from "material-table"

import {
  Edit,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  Clear,
  ArrowUpward,
  Add,
  Check,
} from "@material-ui/icons"



const AdminAudits = () => {

  const { audits } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAudits())
  }, [])




  return (
    <>
      <Typography component="h1" variant="h4">
        Audits
      </Typography>
      <Button onClick={() => dispatch(getAudits())}>Refresh</Button>
      <Typography component="p" variant="body1">
        Last 50 audit items by date
      </Typography>

      {audits && audits.loading && <CircularProgress /> }

      <div style={{ overflowX: "auto" }}>
        {audits && audits.data && audits.data.length > 0 && (
          <MaterialTable
            icons={{
              Edit,
              Delete,
              FirstPage,
              LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search,
              SortArrow: ArrowUpward,
              Add,
              Check,
              Clear,
            }}
            columns={[
              { title: "Item", field: "thing" },
              { title: "Email", field: "email" },
              { title: "Date", field: "date", type: 'datetime' },
              { title: "Amount", field: "amount" },
              { title: "Detail", field: "extra" },
            ]}
            data={JSON.parse(
              JSON.stringify(
                audits.data.map(a => ({
                  ...a,
                  extra: JSON.stringify(a.extra, null, 2),
                  date: new Date(a.date.seconds * 1000),
                }))
              )
            )} //JSON.stringify(obj, null, 2)
            title="Dowloads List"
          />
        )}
      </div>

      {/* <Button onClick={() => dispatch(addFromBackup())}>backup thing</Button>  */}

      {/* <XYPlot height={300} width= {300}>
  <VerticalGridLines />
  <HorizontalGridLines />
  <XAxis />
  <YAxis />
  <LineSeries data={data} />
  <LineSeries data={data2} />

</XYPlot> */}
    </>
  )
}

export default AdminAudits
