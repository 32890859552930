import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import XLSX from 'xlsx'
import { getTowns, addTown, updateTown, getProducts, addFlows, getFlowsList } from '../../state/store'
import { useSelector, useDispatch } from 'react-redux'
import slugify from 'slugify'
import isNaN from 'lodash/isNaN'
import keyBy from 'lodash/keyBy'
import { saveAs } from 'file-saver';

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
}))


const AdminFlows = () => {
  const [excelData, setExcelData] =  useState(null)

  const {flows}  = useSelector(state=>state.db)
  const dispatch = useDispatch()


  useEffect(()=>{
    dispatch(getFlowsList())

  },[])
  return (
    <>
      <Typography component="h1" variant="h4">
        Flows 
      </Typography>

<p>Extremely rough please use with care - Upload file</p>
<p>Download the current flows in csv, adjust then upload</p>

      <div>
                  <label htmlFor="file">Upload flows</label> {" "}
                  <input
                    id="file"
                    type="file"
                    name="flowsFile"
                    onChange={event => {
                    //   setFieldValue("imageFile", event.currentTarget.files[0])
                    var f = event.currentTarget.files[0];
                    var reader = new FileReader();
                    reader.onload = function(e) {
                      var data = new Uint8Array(e.target.result);
                      var workbook = XLSX.read(data, {type: 'array'});
                    //   setExcelData(workbook.Sheets[workbook.SheetNames[0]])
                      console.log('workbook', workbook.Sheets[workbook.SheetNames[0]])
                    console.log('workbook', XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]))
                      setExcelData(XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]]))
                      
                    };
                    reader.readAsArrayBuffer(f);

                    }}
                  />
                </div>

                {/* {excelData && <p>{excelData.B1.v} {excelData.B2.v} {excelData.B3.v}</p>} */}

                {excelData && excelData.length>0 && <p>Loaded the excel data, now hit save</p>}

<br/>
                <Button
                color="primary"
                variant="contained"
                disabled={!excelData}
                onClick={() => {
                  const parsedResult = excelData.map(a=>{
                    // console.log(Object.entries(obj));
                    return {
                      id: slugify(a.Pump+a.Type+a.Panel+a.No_Panels),
                      name: `${a.Type.toLowerCase() ==='multiflow'?'':a.Type+' '}${a.Pump} with ${a.Watts_Needed} (${a.Type.toLowerCase() ==='multiflow'?'Recommended ':''}Solar Panels: ${a.Panel})`,
                      pump: a.Pump,
                      panel: a.Panel,
                      panels: a.No_Panels,
                      panelTips: a.Panel_Tips,
                      type: a.Type,
                      productCode: a.Product_Code,
                      panelCode: a.Panel_Code,
                      maxDepth: a.Max_Depth,
                      wattsNeeded: a.Watts_Needed,
                      flow: Object.entries(a).filter(c=>!isNaN(parseInt(c[0]))).map(b=>b[1])
                    }
                  })
                  dispatch(addFlows(parsedResult))
                }}>Save Flows</Button>

{" "}

<Button
                color="primary"
                variant="contained"
                onClick={() => {
                  if(flows && flows.data && flows.data.length>0){

                    const fixedData = flows.data.map(a=>{
                      // const formatFlows = keyBy(a.flow, (f,i)=>i) //a.flow.map((b, i)=>({[i]:b}))

                      const initialValue = {};
                      const formatFlows = a.flow.reduce((obj, item, currentIndex) => {
                        return {
                          ...obj,
                          [currentIndex]: item,
                        };
                      }, initialValue);

                      return{
                        Pump: a.pump,
                        Type: a.type,
                        Panel: a.panel,
                        Watts_Needed: a.wattsNeeded,
                        No_Panels: a.panels,
                        Product_Code: a.productCode??'',
                        Panel_Code: a.panelCode??'',
                        Panel_Tips: a.panelTips??'',
                        Max_Depth: a.maxDepth??25,
                        ...formatFlows
                      }
                    })

                    const ws = XLSX.utils.json_to_sheet(fixedData, {header:["Pump","Type","Panel", "Watts_Needed", "No_Panels", "Product_Code", "Panel_Code", "Panel_Tips", "Max_Depth"]});
                    // console.log(XLSX.utils.sheet_to_json(aWorkbook))

                    var wb = XLSX.utils.book_new();
XLSX.utils.book_append_sheet(wb, ws, "flows");

                    XLSX.writeFile(wb, 'current_flows.xlsx');
                  }
                }
                  }
                 >Export Current Flows</Button>


                {/* //aoa_to_sheet */}

{/* {excelData && excelData.length>0 && 
       <div>
         {excelData.map(a=><p key={a.Pump+a.Type+a.Panel+a.No_Panels}>{a.Pump} Type: {a.Type} Panel: {a.Panel} No Panels: {a.No_Panels}</p>)}
       </div>
} */}


      {/* {towns && towns.loading && <CircularProgress/>} */}

      {/* <Table aria-label="Add Town Table" style={{ overflowX: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>Add Town</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <FlowRow town={{name:'', location: new firestore.GeoPoint(-29.3441277, 23.3631604), sunHours: [6.5,6.5,6.5,6.5,6.5,6.5,6.5,6.5,6.5,6.5,6.5,6.5]}} save={(town)=>{
                dispatch(addTown(town))
            }}/>
        </TableBody>
      </Table>
      <Button onClick={() => dispatch(getTowns())}>Refresh</Button> */}

      {/* <Table aria-label="Towns Table" style={{ overflowX: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Lat</TableCell>
            <TableCell align="right">Long</TableCell>
            <TableCell align="right">Sun Hours</TableCell>
            <TableCell align="right">Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {towns && towns.data &&
            towns.data.map((row) => <FlowRow key={row.id} town={row} save={(town)=>{
                dispatch(updateTown(town))
            }}/>
            )}
        </TableBody>
      </Table> */}
    </>
  )
}

export default AdminFlows