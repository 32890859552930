import React, { useEffect, useRef, useState } from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import Box from "@material-ui/core/Box"
import {
  getStock,
  addSentWarehouse,
  finishAddSentWarehouse,
  getCurrentWarehouse,
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import dateFormat from "dateformat"
import { Paper } from "@material-ui/core"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormLabel from "@material-ui/core/FormLabel"
import MaterialTable, { MTableToolbar } from "material-table"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form, Field, ErrorMessage, useField } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {
  Edit,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  Clear,
  ArrowUpward,
  Add,
  Check,
  GetApp,
} from "@material-ui/icons"
import { getValidationSchemaWarehouseProduct } from "../validations"
import AlertDialog from "../dialog"
// import isEmpty from 'lodash/isEmpty'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  buttonBottom: theme.spacing(2),
  toolbarRow: {
    marginTop: theme.spacing(2),
    display: "flex",
    justifyContent: "space-between",
  },
}))

const SerialNoTable = ({ ...props }) => {
  const tableRef = useRef()
  const [field, meta, helpers] = useField(props)

  // console.log({...meta.value})

  const [serialList, setSerialList] = useState(field.value)

  const classes = useStyles()

  useEffect(() => {
    if (serialList.length !== 0) {
      helpers.setTouched(true, false)
    } else {
      helpers.setError("No products with serial numbers added")
    }
    helpers.setValue(serialList)
  }, [serialList])

  // useEffect(()=>{
  //     if(isEmpty(meta.value)){
  //         setSerialList([])
  //     }
  // }, [meta.value])

  return (
    <>
      <div className={classes.toolbarRow}>
        <div></div>
        <Button
          className={classes.buttonBottom}
          variant="contained"
          color="primary"
          onClick={() => {
            tableRef.current.setState({
              ...tableRef.current.dataManager.getRenderState(),
              showAddRow: true,
            })
          }}
        >
          Add Product
        </Button>
      </div>
      <MaterialTable
        style={{ marginTop: "10px" }}
        tableRef={tableRef}
        icons={{
          Edit,
          Delete,
          FirstPage,
          LastPage,
          NextPage: ChevronRight,
          PreviousPage: ChevronLeft,
          ResetSearch: Clear,
          Search,
          SortArrow: ArrowUpward,
          Add: props => (
            <Button variant="contained" color="primary">
              Add Product
            </Button>
          ),
          Check,
          Clear,
        }}
        columns={[
          {
            title: "Serial No.",
            field: "serialNo",
            searchable: true,
            validate: rowData => rowData.serialNo && rowData.serialNo !== "",
          },
          {
            title: "Product Name",
            field: "product",
            width: 300,
            searchable: true,
          },
        ]}
        components={{
          Container: props => <Paper {...props} elevation={0} />,
          Toolbar: props => (
            <div>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  tableRef.current.setState({
                    ...tableRef.current.dataManager.getRenderState(),
                    showAddRow: true,
                  })
                }}
              >
                Add Product
              </Button>
              <MTableToolbar
                {...props}
                style={{ height: "2px" }}
                actions={props.actions.filter(a => a.tooltip !== "Add")}
              />
            </div>
          ),
        }}
        editable={{
          onRowAdd: newData =>
            new Promise(resolve => {
              setSerialList([...serialList, { ...newData }])
              resolve()
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise(resolve => {
              const dataUpdate = [...serialList]
              const index = oldData.tableData.id
              dataUpdate[index] = { ...newData }
              setSerialList([...dataUpdate])
              resolve()
            }),
          onRowDelete: oldData =>
            new Promise(resolve => {
              const dataDelete = [...serialList]
              const index = oldData.tableData.id
              dataDelete.splice(index, 1)
              setSerialList([...dataDelete])
              resolve()
            }),
        }}
        localization={{
          body: {
            emptyDataSourceMessage: "Add a product with the button top right",
          },
        }}
        data={JSON.parse(JSON.stringify(serialList))}
        title="Products"
        options={{
          paging: false,
          actionsColumnIndex: -1,
          search: false,
          showTitle: false,
          toolbar: false,
        }}
      />
    </>
  )
}

const AppWarehouse = () => {
  const { sentWarehouse, currentWarehouse } = useSelector(state => state.db)
  const dispatch = useDispatch()

  const classes = useStyles()

  useEffect(() => {
    dispatch(getCurrentWarehouse())
  }, [dispatch])

  useEffect(() => {
    if (tab === 1) {
      dispatch(getCurrentWarehouse())
    }
  }, [tab])

  const [resetTable, setResetTable] = useState(0)

  const [tab, setTab] = useState(0)

  return (
    <Container component="main" maxWidth="md">
      <Typography component="h1" variant="h5">
        Warehouse
      </Typography>

      <Tabs
        value={tab}
        onChange={(_, newVal) => setTab(newVal)}
        aria-label="warehouse tabs"
      >
        <Tab label="Send Out" />
        <Tab label="Sent" />
      </Tabs>

      {tab === 0 && (
        <>
          {sentWarehouse && sentWarehouse.error && (
            <AlertDialog
              title="Error"
              message={sentWarehouse.error.msg}
              closeDialog={() =>
                dispatch(
                  finishAddSentWarehouse({
                    loading: false,
                    msg: null,
                    error: null,
                    data: null,
                  })
                )
              }
            />
          )}

          <Paper className={classes.paper}>
            <Typography variant="h6">Send Out Product</Typography>
            {/* const item = orderList.find(a => a.name === newData.name)
                      dataUpdate[index] = {...newData, amount: getCost(userProfile.roles.role, item)}  */}

            <Formik
              validationSchema={getValidationSchemaWarehouseProduct}
              initialValues={{
                invoiceNo: "",
                notes: "",
                serials: [],
              }}
              onSubmit={(values, actions) => {
                // split out into serial nos
                const asSerials = values.serials.map(a => ({
                  invoiceNo: values.invoiceNo,
                  notes: values.notes,
                  ...a,
                }))
                dispatch(addSentWarehouse(asSerials))
                actions.setSubmitting(false)
              }}
            >
              {({ errors, isSubmitting, values, resetForm, ...props }) => (
                <Form className={classes.form}>
                  {sentWarehouse && sentWarehouse.msg && (
                    <AlertDialog
                      title="Success"
                      message={sentWarehouse.msg}
                      closeDialog={() => {
                        dispatch(
                          finishAddSentWarehouse({
                            loading: false,
                            msg: null,
                            error: null,
                            data: null,
                          })
                        )
                        resetForm()
                        // TODO fix this is junk
                        setResetTable(resetTable + 1)
                      }}
                    />
                  )}
                  <Field type="test" name="invoiceNo">
                    {({ field, form }) => (
                      <FormControl
                        required
                        fullWidth
                        variant="outlined"
                        margin="normal"
                      >
                        <InputLabel htmlFor="invoiceNo">Invoice No</InputLabel>
                        <OutlinedInput
                          id="invoiceNo"
                          {...field}
                          error={
                            form.errors[field.name] && form.touched[field.name]
                              ? true
                              : false
                          }
                          // autoFocus
                          labelWidth={100}
                          required
                        />
                        <ErrorMessage name="invoiceNo">
                          {msg => <FormHelperText>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <Field type="text" name="notes">
                    {({ field, form }) => (
                      <FormControl fullWidth variant="outlined" margin="normal">
                        <InputLabel htmlFor="notes">
                          Any extra notes?
                        </InputLabel>
                        <OutlinedInput
                          id="notes"
                          {...field}
                          error={
                            form.errors[field.name] && form.touched[field.name]
                              ? true
                              : false
                          }
                          // autoFocus
                          labelWidth={220}
                          multiline
                          rows="4"
                        />
                        <ErrorMessage name="notes">
                          {msg => <FormHelperText>{msg}</FormHelperText>}
                        </ErrorMessage>
                      </FormControl>
                    )}
                  </Field>
                  <SerialNoTable name="serials" key={resetTable} />
                  <br />
                  {Object.keys(errors).length > 0 && (
                    <Typography color="error" variant="body2">
                      {errors[Object.keys(errors)[0]]}
                    </Typography>
                  )}
                  <br />
                  <Button
                    variant="contained"
                    color="primary"
                    //   fullWidth
                    disabled={
                      isSubmitting ||
                      // (order && order.loading) ||
                      Object.keys(errors).length > 0
                      //   !validateOrder(orderList)
                    }
                    type="submit"
                  >
                    Submit
                  </Button>{" "}
                </Form>
              )}
            </Formik>
          </Paper>
        </>
      )}

      {tab === 1 && (
        <>
          {currentWarehouse && currentWarehouse.loading && <CircularProgress />}

          <Button
            color="primary"
            variant="contained"
            onClick={() => dispatch(getCurrentWarehouse())}
          >
            Refresh
          </Button>

          <div style={{ overflowX: "auto", marginTop: "5px" }}>
            {currentWarehouse &&
              currentWarehouse.data &&
              currentWarehouse.data.length > 0 && (
                <MaterialTable
                  icons={{
                    Edit,
                    Delete,
                    FirstPage,
                    LastPage,
                    NextPage: ChevronRight,
                    PreviousPage: ChevronLeft,
                    ResetSearch: Clear,
                    Search,
                    SortArrow: ArrowUpward,
                    Add,
                    Check,
                    Clear,
                    Export: GetApp,
                  }}
                  columns={[
                    { title: "Serial No.", field: "serialNo" },
                    { title: "Product", field: "product" },
                    { title: "Invoice No.", field: "invoiceNo" },
                    { title: "Date", field: "date", type: "datetime" },
                    // { title: "Serials", field: "serials",
                    // render: rowData => (
                    //     <span>
                    //       {rowData.serials.map(a=>(<div key={a.serialNo}>{a.serialNo}</div>))}
                    //     </span>
                    //   ),
                    // },

                    { title: "Notes", field: "notes" },
                  ]}
                  data={JSON.parse(
                    JSON.stringify(
                      currentWarehouse.data.map(a => ({
                        ...a,
                        date: new Date(a.date.seconds * 1000),
                      }))
                    )
                  )} //JSON.stringify(obj, null, 2)
                  title="Sent List"
                  options={{
                    pageSize: 20,
                    exportButton: true,
                  }}
                />
              )}
          </div>
        </>
      )}

     
    </Container>
  )
}

export default AppWarehouse

// {currentUser.created
//     ? dateFormat(
//         new Date(currentUser.created.seconds * 1000),
//         "dd mmm yyyy HH:MM"
//       )
//     : ""}
