import React, { useEffect, useState } from "react"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Container from "@material-ui/core/Container"
import {
    getCurrentClaims,
    addClaim
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import { Paper } from "@material-ui/core"
import MaterialTable from "material-table"
import { makeStyles } from "@material-ui/core/styles"
import { Formik, Form, Field, ErrorMessage } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
import {
    Edit,
    Delete,
    FirstPage,
    LastPage,
    ChevronRight,
    ChevronLeft,
    Search,
    Clear,
    ArrowUpward,
    Add,
    Check,
    GetApp,
} from "@material-ui/icons"
import { getValidationSchemaClaim } from "../validations"
import AlertDialog from "../dialog"
import {
    DatePicker,
    MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
// import isEmpty from 'lodash/isEmpty'
import MenuItem from "@material-ui/core/MenuItem"
import Select from "@material-ui/core/Select"
import { firebaseDate } from '../../utils/common'

const useStyles = makeStyles(theme => ({
    paper: {
        marginTop: theme.spacing(2),
        padding: theme.spacing(2),
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    gridList: {
        // width: 500,
        // height: 450,
    },
    buttonBottom: theme.spacing(2),
    toolbarRow: {
        marginTop: theme.spacing(2),
        display: "flex",
        justifyContent: "space-between",
    },
}))


const AppAdminClaims = () => {
    const { claims } = useSelector(state => state.db)
    const dispatch = useDispatch()

    const classes = useStyles()

    useEffect(() => {
        dispatch(getCurrentClaims())
    }, [dispatch])

    useEffect(() => {
        if (tab === 1) {
            dispatch(getCurrentClaims())
        }
    }, [tab])

    const [resetTable, setResetTable] = useState(0)

    const [tab, setTab] = useState(0)

    return (<MuiPickersUtilsProvider utils={DateFnsUtils}>
        <Container component="main" maxWidth="md">
            <Typography component="h1" variant="h5">
                Claims
            </Typography>

            <Tabs
                value={tab}
                onChange={(_, newVal) => setTab(newVal)}
                aria-label="claims tabs"
            >
                <Tab label="Claim Form" />
                <Tab label="Claims" />
            </Tabs>

            {tab === 0 && (
                <>
                    {claims && claims.error && (
                        <AlertDialog
                            title="Error"
                            message={claims.error.msg}
                            closeDialog={() =>
                                dispatch(getCurrentClaims())
                            }
                        />
                    )}

                    <Paper className={classes.paper}>
                        <Typography variant="h6">Record Claim</Typography>
                        {/* const item = orderList.find(a => a.name === newData.name)
                      dataUpdate[index] = {...newData, amount: getCost(userProfile.roles.role, item)}  */}

                        <Formik
                            validationSchema={getValidationSchemaClaim}
                            initialValues={{
                                invoiceNo: "",
                                purchaseDate: new Date(),
                                failureDate: new Date(),
                                supplier: "",
                                supplierOther: "",
                                productModel: "",
                                serialNo: "",
                                approvedBy: "",
                                ticketNo: "", notes: ""
                            }}
                            onSubmit={(values, actions) => {
                                console.log({ values })
                                // put the supplierOther in supplier if other
                                if (values.supplier === "Other") {
                                    dispatch(addClaim({ ...values, supplier: values.supplierOther }))
                                } else {
                                    dispatch(addClaim({ ...values }))
                                }
                                actions.setSubmitting(false)
                            }}
                        >
                            {({ errors, isSubmitting, values, resetForm, setFieldValue, ...props }) => (
                                <Form className={classes.form}>
                                    {claims && claims.msg && (
                                        <AlertDialog
                                            title="Success"
                                            message={claims.msg}
                                            closeDialog={() => {
                                                dispatch(getCurrentClaims())
                                                resetForm()
                                                // TODO fix this is junk
                                                setResetTable(resetTable + 1)
                                            }}
                                        />
                                    )}
                                    <Field type="text" name="invoiceNo">
                                        {({ field, form }) => (
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                <InputLabel htmlFor="invoiceNo">Invoice No</InputLabel>
                                                <OutlinedInput
                                                    id="invoiceNo"
                                                    {...field}
                                                    error={
                                                        form.errors[field.name] && form.touched[field.name]
                                                            ? true
                                                            : false
                                                    }
                                                    // autoFocus
                                                    labelWidth={100}
                                                    required
                                                />
                                                <ErrorMessage name="invoiceNo">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field type="date" name="purchaseDate">
                                        {({ field, form }) => (
                                            <DatePicker
                                                disableFuture
                                                id="purchaseDate"
                                                value={field.value}
                                                // error={
                                                //   form.errors[field.name] && form.touched[field.name]
                                                //     ? true
                                                //     : false
                                                // }
                                                margin="normal"
                                                label="Purchase Date"
                                                format="dd/MM/yyyy"
                                                // onAccept={(date)=>setFieldValue('invoiceDate', date)}
                                                onChange={date => setFieldValue("purchaseDate", date)}
                                            />
                                        )}
                                    </Field>{"  "}
                                    <Field type="date" name="failureDate">
                                        {({ field, form }) => (
                                            <DatePicker
                                                disableFuture
                                                id="failureDate"
                                                value={field.value}
                                                // error={
                                                //   form.errors[field.name] && form.touched[field.name]
                                                //     ? true
                                                //     : false
                                                // }
                                                margin="normal"
                                                label="Failure Date"
                                                format="dd/MM/yyyy"
                                                // onAccept={(date)=>setFieldValue('invoiceDate', date)}
                                                onChange={date => setFieldValue("failureDate", date)}
                                            />
                                        )}
                                    </Field>
                                    <Field type="text" name="supplier">
                                        {({ field, form }) => (
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                <InputLabel htmlFor="supplier">Supplier</InputLabel>

                                                <Select
                                                    labelWidth={150}
                                                    id="supplier"
                                                    type="select"
                                                    name="supplier"
                                                    required
                                                    {...field}
                                                >
                                                    <MenuItem value="Premium Pumps">Premium Pumps</MenuItem>
                                                    <MenuItem value="Veichi">Veichi</MenuItem>
                                                    <MenuItem value="Spitfire">Spitfire</MenuItem>
                                                    <MenuItem value="Multiflow">Multiflow</MenuItem>
                                                    <MenuItem value="Other">Other</MenuItem>
                                                </Select>
                                                <ErrorMessage name="supplier">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    {values && values.supplier === "Other" && <Field type="text" name="supplierOther">
                                        {({ field, form }) => (
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                <InputLabel htmlFor="supplierOther">Supplier Name (Other was selected)</InputLabel>

                                                <OutlinedInput
                                                    id="supplierOther"
                                                    {...field}
                                                    error={
                                                        form.errors[field.name] && form.touched[field.name]
                                                            ? true
                                                            : false
                                                    }
                                                    // autoFocus
                                                    labelWidth={100}
                                                    required
                                                />
                                                <ErrorMessage name="supplierOther">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>}
                                    <Field type="text" name="productModel">
                                        {({ field, form }) => (
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                <InputLabel htmlFor="productModel">Product Model</InputLabel>
                                                <OutlinedInput
                                                    id="productModel"
                                                    {...field}
                                                    error={
                                                        form.errors[field.name] && form.touched[field.name]
                                                            ? true
                                                            : false
                                                    }
                                                    // autoFocus
                                                    labelWidth={100}
                                                    required
                                                />
                                                <ErrorMessage name="productModel">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field type="text" name="serialNo">
                                        {({ field, form }) => (
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                <InputLabel htmlFor="serialNo">Serial No</InputLabel>
                                                <OutlinedInput
                                                    id="serialNo"
                                                    {...field}
                                                    error={
                                                        form.errors[field.name] && form.touched[field.name]
                                                            ? true
                                                            : false
                                                    }
                                                    // autoFocus
                                                    labelWidth={100}
                                                    required
                                                />
                                                <ErrorMessage name="serialNo">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field type="text" name="approvedBy">
                                        {({ field, form }) => (
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                <InputLabel htmlFor="approvedBy">Approved By</InputLabel>
                                                <OutlinedInput
                                                    id="approvedBy"
                                                    {...field}
                                                    error={
                                                        form.errors[field.name] && form.touched[field.name]
                                                            ? true
                                                            : false
                                                    }
                                                    // autoFocus
                                                    labelWidth={100}
                                                    required
                                                />
                                                <ErrorMessage name="approvedBy">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field type="text" name="ticketNo">
                                        {({ field, form }) => (
                                            <FormControl
                                                required
                                                fullWidth
                                                variant="outlined"
                                                margin="normal"
                                            >
                                                <InputLabel htmlFor="ticketNo">Ticket No</InputLabel>
                                                <OutlinedInput
                                                    id="ticketNo"
                                                    {...field}
                                                    error={
                                                        form.errors[field.name] && form.touched[field.name]
                                                            ? true
                                                            : false
                                                    }
                                                    // autoFocus
                                                    labelWidth={100}
                                                    required
                                                />
                                                <ErrorMessage name="ticketNo">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <Field type="text" name="notes">
                                        {({ field, form }) => (
                                            <FormControl fullWidth variant="outlined" margin="normal">
                                                <InputLabel htmlFor="notes">
                                                    Any extra notes?
                                                </InputLabel>
                                                <OutlinedInput
                                                    id="notes"
                                                    {...field}
                                                    error={
                                                        form.errors[field.name] && form.touched[field.name]
                                                            ? true
                                                            : false
                                                    }
                                                    // autoFocus
                                                    labelWidth={220}
                                                    multiline
                                                    rows="4"
                                                />
                                                <ErrorMessage name="notes">
                                                    {msg => <FormHelperText>{msg}</FormHelperText>}
                                                </ErrorMessage>
                                            </FormControl>
                                        )}
                                    </Field>
                                    <br />
                                    {Object.keys(errors).length > 0 && (
                                        <Typography color="error" variant="body2">
                                            {errors[Object.keys(errors)[0]]}
                                        </Typography>
                                    )}
                                    <br />
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        //   fullWidth
                                        disabled={
                                            isSubmitting ||
                                            // (order && order.loading) ||
                                            Object.keys(errors).length > 0
                                            //   !validateOrder(orderList)
                                        }
                                        type="submit"
                                    >
                                        Submit
                                    </Button>{" "}
                                </Form>
                            )}
                        </Formik>
                    </Paper>
                </>
            )}

            {tab === 1 && (
                <>
                    {claims && claims.loading && <CircularProgress />}

                    <Button
                        color="primary"
                        variant="contained"
                        onClick={() => dispatch(getCurrentClaims())}
                    >
                        Refresh
                    </Button>

                    <div style={{ overflowX: "auto", marginTop: "5px" }}>
                        {claims &&
                            claims.data &&
                            claims.data.length > 0 && (
                                <MaterialTable
                                    icons={{
                                        Edit,
                                        Delete,
                                        FirstPage,
                                        LastPage,
                                        NextPage: ChevronRight,
                                        PreviousPage: ChevronLeft,
                                        ResetSearch: Clear,
                                        Search,
                                        SortArrow: ArrowUpward,
                                        Add,
                                        Check,
                                        Clear,
                                        Export: GetApp,
                                    }}
                                    columns={[
                                        { title: "Invoice No.", field: "invoiceNo", searchable: true },
                                        { title: "Purchase Date", field: "purchaseDate", type: "date", searchable: false },
                                        { title: "Failure Date", field: "failureDate", type: "date", searchable: false },
                                        { title: "Supplier", field: "supplier", searchable: true },
                                        { title: "Product Model", field: "productModel", searchable: true },
                                        { title: "Serial No.", field: "serialNo", searchable: true },
                                        { title: "Approved By", field: "approvedBy", searchable: false },
                                        { title: "Ticket No.", field: "ticketNo", searchable: true },

                                        { title: "Notes", field: "notes", searchable: false },
                                    ]}
                                    data={JSON.parse(
                                        JSON.stringify(
                                            claims.data.map(a => ({
                                                ...a,
                                                date: firebaseDate(a.date),
                                                purchaseDate: firebaseDate(a.purchaseDate),
                                                failureDate: firebaseDate(a.failureDate),
                                            }))
                                        )
                                    )} //JSON.stringify(obj, null, 2)
                                    title="Claims List"
                                    options={{
                                        pageSize: 20,
                                        exportButton: true,
                                    }}
                                />
                            )}
                    </div>
                </>
            )}


        </Container>
    </MuiPickersUtilsProvider>
    )
}

export default AppAdminClaims

// {currentUser.created
//     ? dateFormat(
//         new Date(currentUser.created.seconds * 1000),
//         "dd mmm yyyy HH:MM"
//       )
//     : ""}
