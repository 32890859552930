import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import {
    finishAddAlertMessage,
  getAlertMessage,
  addAlertMessage,
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import { Formik, Form, Field, ErrorMessage } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"
import {Select, MenuItem} from "@material-ui/core"


import { getValidationSchemaAlertMessage } from "../validations"
import { Paper } from "@material-ui/core"

import AlertDialog from "../../components/dialog"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonBottom: theme.spacing(2),
  toolbarRow: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const AdminAlertMessage = () => {
  const dispatch = useDispatch()
  const { alertMessage, userProfile } = useSelector(state => state.db)
  const classes = useStyles()

  useEffect(() => {
    dispatch(getAlertMessage())
  }, [])

  return (
    <Container component="main" maxWidth="md">
      {alertMessage && alertMessage.error && (
        <AlertDialog
          title="Error"
          message={alertMessage.error.msg}
          closeDialog={() =>
            dispatch(
                finishAddAlertMessage({ loading: false, msg: null, error: null })
            )
          }
        />
      )}
      {alertMessage && alertMessage.msg && (
        <AlertDialog
          title="Saved"
          message={alertMessage.msg}
          closeDialog={() =>
            dispatch(
                finishAddAlertMessage({ loading: false, msg: null, error: null })
            )
          }
        />
      )}
      <Typography component="h1" variant="h4">
        Banner Message
      </Typography>
      <br />
      <Typography component="p" variant="body1">
        Adjust the banner message that appears on top of the header.
      </Typography>
      <br />
      {alertMessage && !alertMessage.loading && (
        <Paper className={classes.paper}>
          <Formik
            validationSchema={getValidationSchemaAlertMessage}
            initialValues={{
                alertType: alertMessage && alertMessage.data && alertMessage.data.alertType
                ? alertMessage.data.alertType
                : "warning",
              message:
              alertMessage && alertMessage.data && alertMessage.data.message
                  ? alertMessage.data.message
                  : "",
            }}
            onSubmit={(values, actions) => {
                dispatch(
                    addAlertMessage({message: values.message, alertType: values.alertType})
                )

              actions.setSubmitting(false)
            }}
          >
            {({ errors, isSubmitting, values }) => (
              <Form className={classes.form}>
                <Field
                  type="text"
                  name="message"
                  >{({ field, form }) => (
                    <FormControl
                      
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    >
                      <InputLabel htmlFor="message">Message</InputLabel>
                      <OutlinedInput
                        id="message"
                        {...field}
                        error={
                          form.errors[field.message] && form.touched[field.message]
                            ? true
                            : false
                        }
                        // autoFocus
                        label="Message"
                        
                      />
                      <ErrorMessage name="message">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                </Field>

                <Field
                type="text"
                name="alertType"
              >{({ field }) => (
                <FormControl fullWidth variant="outlined" margin="normal">
                  <InputLabel htmlFor="alertType">
                    Alert Type
                  </InputLabel>

                  <Select
                    labelWidth={200}
                    id="alertType"
                    type="select"
                    name="alertType"
                    {...field}
                  >
                    <MenuItem value="warning">Warning (orange)</MenuItem>
                    <MenuItem value="info">Info (blueish)</MenuItem>
                    <MenuItem value="error">Error (red)</MenuItem>
                    <MenuItem value="success">Success (green)</MenuItem>
                  </Select>

                  <ErrorMessage name="alertType">
                    {msg => <FormHelperText>{msg}</FormHelperText>}
                  </ErrorMessage>
                </FormControl>
              )}
              </Field>
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  //   fullWidth
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  type="submit"
                >
                  Save
                </Button>{" "}
              </Form>
            )}
          </Formik>
        </Paper>
      )}
    </Container>
  )
}

export default AdminAlertMessage
