import React, { useState, useEffect } from "react"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import XLSX from "xlsx"

import { getTowns, getFlowsList } from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import { CustomerForm } from "../sizing/basic"
import { PumpCalulation, PumpCalulationAdjusted } from "../pumps/pumps-new"

const AdminCalculations = () => {
  const [calculationLoading, setCalculationLoading] = useState(false)
  const { towns, flows } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTowns())
    dispatch(getFlowsList())
  }, [])

  return (
    <>
      <Typography component="h1" variant="h5">
        Test Calculations
      </Typography>
      {towns && towns.loading && flows && flows.loading && <CircularProgress />}

      {towns && towns.data && flows && flows.data && (
        <CustomerForm
          isSubmitting={calculationLoading}
          initialValues={{
            customer: "",
            phone: "",
            email: "",
            waterLevel: 20,
            pumpDistance: 10,
            pumpHeight: 5,
            waterNeeded: 5000,
            pipeDiameter: 32,
            town: towns.data.find(a => a.name === "Unknown"),
            totalWellDepth: 50,
          }}
          saveResult={values => {
            setCalculationLoading(true)
            const cffl = PumpCalulation(
              values.waterLevel,
              values.pumpDistance,
              values.pipeDiameter,
              values.pumpHeight,
              values.waterNeeded,
              values.town,
              values.totalWellDepth,
              "all",
              flows.data,
              values.customer,
              values.phone,
              values.email
            ) //.filter(pump => pump.pumpWorks)
            const rffl = PumpCalulationAdjusted(
              values.waterLevel,
              values.pumpDistance,
              values.pipeDiameter,
              values.pumpHeight,
              values.waterNeeded,
              values.town,
              values.totalWellDepth,
              "all",
              flows.data,
              values.customer,
              values.phone,
              values.email
            ) //.filter(pump => pump.pumpWorks)

            const maxLeng = Math.max(cffl.length, rffl.length)

            let displayResults = []

            for (let i = 0; i < maxLeng; i++) {
              displayResults[i] = [
                cffl[i]?.name,
                cffl[i]?.totalFriction,
                cffl[i]?.averageflowPerDay,
                cffl[i]?.pumpWorks,
                rffl[i]?.name,
                rffl[i]?.totalFriction,
                rffl[i]?.averageflowPerDay,
                rffl[i]?.pumpWorks,
              ]
            }

            var wb = XLSX.utils.book_new()

            var ws = XLSX.utils.aoa_to_sheet([
              ["waterLevel", values.waterLevel],
              ["pumpHeight", values.pumpHeight],
              ["pumpDistance", values.pumpDistance],
              ["pipeDiameter", values.pipeDiameter],
              ["waterNeeded", values.waterNeeded],
              ["town", values.town.name],
              ["totalWellDepth", values.totalWellDepth],
              [],
              [`CFFL`, ``, ``, `RFFL`],
              [],
              [
                `name`,
                `fric`,
                `water per day`,
                `works`,
                `name`,
                `fric`,
                `water per day`,
                `works`
              ],
              ...displayResults,
            ])

            wb.SheetNames.push(
              `dis-${values.pumpDistance}-level-${values.waterLevel}`
            )

            wb.Sheets[
              `dis-${values.pumpDistance}-level-${values.waterLevel}`
            ] = ws

            const fixedData = flows.data.map(a => {
              const initialValue = {}
              const formatFlows = a.flow.reduce((obj, item, currentIndex) => {
                return {
                  ...obj,
                  [currentIndex]: item,
                }
              }, initialValue)

              return {
                Pump: a.pump,
                Type: a.type,
                Panel: a.panel,
                No_Panels: a.panels,
                Panel_Tips: a.panelTips,
                ...formatFlows,
              }
            })

            const wsFlows = XLSX.utils.json_to_sheet(fixedData, {
              header: ["Pump", "Type", "Panel", "No_Panels", "Panel_Tips"],
            })

            XLSX.utils.book_append_sheet(wb, wsFlows, "flows")

            XLSX.writeFile(wb, `results-${Date.now()}.xlsx`)

            setCalculationLoading(false)
          }}
        />
      )}
    </>
  )
}

export default AdminCalculations
