import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { rolesList, RESELLER } from "../../utils/checkRoles"
import dateFormat from "dateformat"
import {
  getUsers,
  updateRoles,
  getUserApplication,
  finishApplication,
  updateUserEmail,
  getIdToken,
  updateUserRep,
  updateUserCompany,
  getReps
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import XLSX from "xlsx"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import TextField from "@material-ui/core/TextField"
import MaterialTable from "material-table"
import Box from "@material-ui/core/Box"
import {
  Edit,
  Delete,
  FirstPage,
  LastPage,
  ChevronRight,
  ChevronLeft,
  Search,
  Clear,
  ArrowUpward,
  Add,
  Check,
} from "@material-ui/icons"
import keyBy from 'lodash/keyBy'
import mapValues from 'lodash/mapValues'

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  gridList: {
    // width: 500,
    // height: 450,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  addButton: {
    margin: theme.spacing(1),
  },
}))

const AdminUsers = () => {
  const [editUser, setEditUser] = useState(null)
  const [changeEmail, setChangeEmail] = useState(null)
  const [deleteUserShow, setDeleteUserShow] = useState(null)

  const { users, application, reps } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getUsers())
    dispatch(getReps())
  }, [])

  return (
    <>
      {application && application.data && (
        <ApplicationDialog
          user={application.data}
          closeDialog={() =>
            dispatch(
              finishApplication({
                loading: false,
                msg: null,
                error: null,
                data: null,
              })
            )
          }
        />
      )}
      {editUser && (
        <EditDialog
          user={editUser}
          closeDialog={() => {
            setEditUser(null)
            dispatch(getUsers())
          }}
        />
      )}
      {changeEmail && (
        <ChangeUserDialog
          user={changeEmail}
          closeDialog={() => {
            setChangeEmail(null)
            dispatch(getUsers())
          }}
        />
      )}
      {deleteUserShow && (
        <DeleteUserDialog
          user={deleteUserShow}
          closeDialog={() => {
            setDeleteUserShow(null)
            dispatch(getUsers())
          }}
        />
      )}
      <Typography component="h1" variant="h5">
        Users {users && users.data && users.data.length}
      </Typography>
      {users && users.loading && <CircularProgress />}
      <Button
        color="primary"
        variant="contained"
        onClick={() => dispatch(getUsers())}
      >
        Refresh
      </Button>{" "}
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          if (users && users.data) {
            const fixedData = users.data.map(a => {
              return {
                Email: a.email,
                FirstName: a.firstName,
                LastName: a.lastName,
                Phone: a.phone,
                Company: a.companyName ? a.companyName : "",
                Roles: a.roles ? a.roles.join(", ") : "",
                Created: a.created
                  ? dateFormat(
                      new Date(a.created.seconds * 1000),
                      "dd mmm yyyy HH:MM"
                    )
                  : "",
                Rep: a.rep ? a.rep : "",
              }
            })

            const ws = XLSX.utils.json_to_sheet(fixedData)

            var wb = XLSX.utils.book_new()
            XLSX.utils.book_append_sheet(wb, ws, "Users")

            XLSX.writeFile(wb, "users.xlsx")
          }
        }}
      >
        Export Users
      </Button>
      <Box marginTop="10px">
        {users && users.data && reps && reps.data && (
          <MaterialTable
            icons={{
              Edit,
              Delete,
              FirstPage,
              LastPage,
              NextPage: ChevronRight,
              PreviousPage: ChevronLeft,
              ResetSearch: Clear,
              Search,
              SortArrow: ArrowUpward,
              Add,
              Check,
              Clear,
            }}
            columns={[
              {
                title: "Email",
                field: "email",
                searchable: true,
                editable: "never",
                width: 200,
                cellStyle:{
                  wordBreak: 'break-all'
                }
              },
              {
                title: "Name",
                field: "firstName",
                searchable: true,
                render: rowData => (
                  <span>
                    {rowData.firstName} {rowData.lastName}
                  </span>
                ),
                editable: "never",
                width: 150,
                cellStyle:{
                  wordBreak: 'break-word'
                }
              },
              {
                title: "Last Name",
                field: "lastName",
                searchable: true,
                editable: "never",
                hidden: true,
                width: 150,
              },
              {
                title: "Company",
                field: "companyName",
                emptyValue: "none",
                searchable: true,
                width: 200,
                cellStyle:{
                  wordBreak: 'break-word'
                }
              },
              {
                title: "Phone",
                field: "phone",
                searchable: true,
                editable: "never",
                width: 150,
              },

              {
                title: "Created",
                field: "date",
                searchable: false,
                render: rowData => (
                  <span>
                    {rowData.created &&
                      dateFormat(
                        new Date(rowData.created.seconds * 1000),
                        "dd mmm yyyy HH:MM"
                      )}
                  </span>
                ),
                editable: "never",
                width: 150,
              },

              {
                title: "Rep",
                field: "rep",
                searchable: false,
                initialEditValue: "none",
                width: 150,
                lookup: {...mapValues(keyBy(reps.data, 'id'), 'id'), none: 'none'},
              },

              {
                title: "Roles",
                field: "roles",
                searchable: false,
                render: rowData => rowData.roles.join(", "),
                editable: "never",
                width: 150,
              },

              {
                title: "Roles Edit",
                field: "id",
                searchable: false,
                render: rowData => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setEditUser(rowData)}
                  >
                    Edit
                  </Button>
                ),
                editable: "never",
                width: 120,
              },

              {
                title: "Application",
                field: "id",
                searchable: false,
                render: rowData => (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => dispatch(getUserApplication(rowData.id))}
                  >
                    Application
                  </Button>
                ),
                editable: "never",
                width: 150,
              },

              {
                title: "Other",
                field: "id",
                searchable: false,
                render: rowData => (<div style={{display: 'flex'}}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setChangeEmail(rowData)}
                  >
                    Email/Pass
                  </Button>
                  <Button
                  variant="contained"
                  color="primary"
                  style={{marginLeft: '10px'}}
                  onClick={() => setDeleteUserShow(rowData)}
                >
                  Delete
                </Button></div>
                ),
                editable: "never",
                width: 200,
              },
            ]}
            cellEditable={{
              onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                return new Promise(resolve => {
                  if (columnDef.field === "rep") {
                    dispatch(updateUserRep(rowData.id, newValue))
                    // dispatch(getUsers())
                  } else if (columnDef.field === "companyName") {
                    dispatch(updateUserCompany(rowData.id, newValue))
                    // dispatch(getUsers())
                  } else {
                    resolve()
                  }
                })
              },
            }}
            data={JSON.parse(
              JSON.stringify(
                users.data.map(a => ({
                  ...a,
                  rep: a.rep ? a.rep : "none",
                  companyName: a.companyName ? a.companyName : "none",
                }))
              )
            )}
            title="Users"
            options={{
              pageSize: 20,
              actionsColumnIndex: -1,
              tableLayout: "fixed",
            }}
          />
        )}
      </Box>
    </>
  )
}

const ApplicationDialog = ({ user, closeDialog }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    //   setOpen(false);
    closeDialog()
  }

  return (
    <div>
      <Dialog
        open={user ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xs"
      >
        <DialogTitle id="alert-dialog-title">Application</DialogTitle>
        {user && !user.firstName && (
          <DialogContent>
            <DialogContentText>
              <b>User has not filled out the application yet.</b>
            </DialogContentText>
          </DialogContent>
        )}
        {user && user.firstName && (
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>firstName</b>: {user.firstName}
              <br />
              <b>lastName</b>: {user.lastName}
              <br />
              <b>businessName</b>: {user.businessName}
              <br />
              <b>deliveryAddress</b>: {user.deliveryAddress}
              <br />
              <b>email</b>: {user.email}
              <br />
              <b>job</b>: {user.job}
              <br />
              <b>phone</b>: {user.phone}
              <br />
              <b>province</b>: {user.province}
              <br />
              <b>town</b>: {user.town}
              <br />
              <b>vatNumber</b>: {user.vatNumber}
              <br />
              <b>Warehouse</b>: {user.warehouse}
              <br />
            </DialogContentText>
          </DialogContent>
        )}
        <DialogActions>
          {user && user.firstName && (
            <Button
              onClick={() => {
                dispatch(updateRoles(user.uid, [RESELLER]))
                dispatch(getUsers())
              }}
              color="primary"
              autoFocus
            >
              Remove APPLICANT make RESELLER
            </Button>
          )}

          <Button onClick={handleClose} color="primary">
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

async function changeEmail(uid, email, password) {
  const token = await getIdToken()
  let response = await fetch(
    process.env.NODE_ENV === 'development'?`http://localhost:5002/cedar-pumps/us-central1/changeUser`:`https://us-central1-cedar-pumps.cloudfunctions.net/changeUser`,
    {
      method: "POST",
      body: JSON.stringify({ uid, email, password }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response.status !== 200) {
    throw new Error("Get Failed")
  }
  // let data = await response.json()
  return "Ok"
}

async function deleteUserAdmin(uid) {
  const token = await getIdToken()
  let response = await fetch(
    process.env.NODE_ENV === 'development'?`http://localhost:5002/cedar-pumps/us-central1/deleteUser`:`https://us-central1-cedar-pumps.cloudfunctions.net/deleteUser`,
    {
      method: "POST",
      body: JSON.stringify({ uid }),
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response.status !== 200) {
    throw new Error("Get Failed")
  }
  // let data = await response.json()
  return "Ok"
}

const ChangeUserDialog = ({ user, closeDialog }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    //   setOpen(false);
    closeDialog()
  }

  const [email, setEmail] = useState(user.email)
  const [password, setPassword] = useState("")

  const [loading, setLoading] = useState(false)

  return (
    <div>
      <Dialog
        open={user ? true : false}
        onClose={handleClose}
        aria-labelledby="change-user"
        aria-describedby="change-user"
        fullWidth={true}
        maxWidth="xs"
        //   style={{minWidth: '300px'}}
      >
        <DialogTitle>Manual Change User Email/Password</DialogTitle>

        <DialogContent>
          {loading && <CircularProgress />}
          <DialogContentText id="alert-dialog-description">
            Change the email or set a password. Make sure email is valid.
            Remember to send the password to the user if this is successful.
          </DialogContentText>
          <TextField
            fullWidth
            label="Email"
            variant="outlined"
            value={email}
            onChange={a => setEmail(a.target.value)}
          />
          <br /> <br />
          <TextField
            fullWidth
            label="Password"
            variant="outlined"
            value={password}
            onChange={a => setPassword(a.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true)
              try {
                await changeEmail(user.id, email, password)
                dispatch(updateUserEmail(user.id, email))
                alert("Changed Ok")
              } catch (error) {
                console.error("cannot update user")
                alert("Cannot update the user")
              }

              setLoading(false)
              handleClose()
            }}
            color="primary"
            autoFocus
          >
            Change
          </Button>

          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const DeleteUserDialog = ({ user, closeDialog }) => {
  const dispatch = useDispatch()

  const handleClose = () => {
    //   setOpen(false);
    closeDialog()
  }

  const [loading, setLoading] = useState(false)

  return (
    <div>
      <Dialog
        open={user ? true : false}
        onClose={handleClose}
        aria-labelledby="delete-user"
        aria-describedby="delete-user"
        fullWidth={true}
        maxWidth="xs"
        //   style={{minWidth: '300px'}}
      >
        <DialogTitle>Are you sure you want to delete this user?</DialogTitle>

        <DialogContent>
          {loading && <CircularProgress />}
          <DialogContentText id="alert-dialog-description">
            Sorry for the confirm thing, but this is a dangerous action. Hard to put the user back.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={loading}
            onClick={async () => {
              setLoading(true)
              try {
                await deleteUserAdmin(user.id)
                dispatch(getUsers())
              } catch (error) {
                console.error("cannot delete user")
                alert("Cannot delete the user. You might have to logout and login again.")
              }

              setLoading(false)
              handleClose()
            }}
            color="primary"
            autoFocus
          >
            Delete User
          </Button>

          <Button onClick={handleClose} color="primary" autoFocus>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

const EditDialog = ({ user, closeDialog }) => {
  const [currentRole, setCurrentRole] = useState("")
  const [currentRolesList, setCurrentRolesList] = useState(user.roles)

  const { roles } = useSelector(state => state.db)

  const dispatch = useDispatch()

  const classes = useStyles()
  const handleClose = () => {
    //   setOpen(false);
    closeDialog()
  }

  return (
    <div>
      <Dialog
        open={user ? true : false}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth={true}
        maxWidth="xs"
        //   style={{minWidth: '300px'}}
      >
        <DialogTitle id="alert-dialog-title">Edit Roles</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {user && user.email}
            <br />
            Current Roles: {currentRolesList.join(", ")}
            {/* rolesList */}
          </DialogContentText>
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="role-select">Role</InputLabel>
            <Select
              labelId="role-select"
              id="role-select"
              //   value={age}
              //   onChange={handleChange}
              labelWidth={50}
              value={currentRole}
              onChange={e => {
                setCurrentRole(e.target.value)
              }}
            >
              {/* <MenuItem value="">
                <em>None</em>
              </MenuItem> */}
              {rolesList.map(a => (
                <MenuItem key={a} value={a}>
                  {a}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="primary"
            className={classes.addButton}
            onClick={() => {
              if (
                currentRole === "" ||
                currentRolesList.find(a => a === currentRole)
              ) {
                //already exists
                console.log("already exists")
              } else {
                const newRoles = [...currentRolesList, currentRole]
                setCurrentRolesList(newRoles)
                dispatch(updateRoles(user.id, newRoles))
              }
            }}
          >
            Add
          </Button>
          <Button
            variant="contained"
            className={classes.addButton}
            onClick={() => {
              if (currentRolesList.find(a => a === currentRole)) {
                const newRoles = currentRolesList.filter(a => a !== currentRole)
                setCurrentRolesList(newRoles)
                dispatch(updateRoles(user.id, newRoles))
              }
            }}
          >
            Remove
          </Button>
          {roles && roles.loading && <CircularProgress />}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AdminUsers
