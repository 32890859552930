import React, { useEffect } from "react"
import Button from "@material-ui/core/Button"
import { makeStyles } from "@material-ui/core/styles"
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container"
import {
  finishRepDetails,
  getRepDetails,
  updateRepDetails,
} from "../../state/store"
import { useSelector, useDispatch } from "react-redux"
import { Formik, Form, Field, ErrorMessage } from "formik"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import InputLabel from "@material-ui/core/InputLabel"
import OutlinedInput from "@material-ui/core/OutlinedInput"

import { getValidationSchemaRep } from "../validations"
import { Paper } from "@material-ui/core"

import AlertDialog from "../../components/dialog"

const useStyles = makeStyles(theme => ({
  paper: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2),
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  buttonBottom: theme.spacing(2),
  toolbarRow: {
    display: "flex",
    justifyContent: "space-between",
  },
}))

const AdminRep = () => {
  const dispatch = useDispatch()
  const { repDetails, userProfile } = useSelector(state => state.db)
  const classes = useStyles()

  useEffect(() => {
    dispatch(getRepDetails())
  }, [])

  return (
    <Container component="main" maxWidth="md">
      {repDetails && repDetails.error && (
        <AlertDialog
          title="Error"
          message={repDetails.error.msg}
          closeDialog={() =>
            dispatch(
              finishRepDetails({ loading: false, msg: null, error: null })
            )
          }
        />
      )}
      {repDetails && repDetails.msg && (
        <AlertDialog
          title="Saved"
          message={repDetails.msg}
          closeDialog={() =>
            dispatch(
              finishRepDetails({ loading: false, msg: null, error: null })
            )
          }
        />
      )}
      <Typography component="h1" variant="h4">
        Rep Details
      </Typography>
      <br />
      <Typography component="p" variant="body1">
        Adjust your rep details, this gets show to the agents. The code must
        have no spaces and can be show to the agent in future. The code can only
        be set once, contact developer if you need it changed.
      </Typography>
      <br />
      {repDetails && !repDetails.loading && (
        <Paper className={classes.paper}>
          <Formik
            validationSchema={getValidationSchemaRep}
            initialValues={{
              name:
                repDetails && repDetails.data && repDetails.data.name
                  ? repDetails.data.name
                  : userProfile.firstName,
              email:
                repDetails && repDetails.data && repDetails.data.email
                  ? repDetails.data.email
                  : userProfile.email,
              phone:
                repDetails && repDetails.data && repDetails.data.phone
                  ? repDetails.data.phone
                  : userProfile.phone,
              code:
                repDetails && repDetails.data && repDetails.data.code
                  ? repDetails.data.code
                  : "",
            }}
            onSubmit={(values, actions) => {
              // so the code is already set this is an update
              if (repDetails && repDetails.data && repDetails.data.id) {
                dispatch(
                  updateRepDetails({
                    ...values,
                    code: repDetails.data.id,
                  })
                )
              } else {
                dispatch(
                  updateRepDetails({
                    ...values,
                  })
                )
              }

              actions.setSubmitting(false)
            }}
          >
            {({ errors, isSubmitting, values }) => (
              <Form className={classes.form}>
                <Field
                  type="text"
                  name="name"
                  render={({ field, form }) => (
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    >
                      <InputLabel htmlFor="name">Name</InputLabel>
                      <OutlinedInput
                        id="name"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                        // autoFocus
                        labelWidth={60}
                        required
                      />
                      <ErrorMessage name="name">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  type="email"
                  name="email"
                  render={({ field, form }) => (
                    <FormControl
                      required
                      fullWidth
                      variant="outlined"
                      margin="normal"
                    >
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <OutlinedInput
                        id="email"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                        autoComplete="email"
                        // autoFocus
                        labelWidth={60}
                        required
                      />
                      <ErrorMessage name="email">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  type="text"
                  name="phone"
                  render={({ field, form }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                    >
                      <InputLabel htmlFor="phone">Phone</InputLabel>
                      <OutlinedInput
                        id="phone"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                        // autoFocus
                        labelWidth={60}
                        required
                      />
                      <ErrorMessage name="phone">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                />
                <Field
                  type="text"
                  name="code"
                  render={({ field, form }) => (
                    <FormControl
                      fullWidth
                      variant="outlined"
                      margin="normal"
                      required
                    >
                      <InputLabel htmlFor="code">Code</InputLabel>
                      <OutlinedInput
                        required
                        id="phone"
                        {...field}
                        error={
                          form.errors[field.name] && form.touched[field.name]
                            ? true
                            : false
                        }
                        // autoFocus
                        labelWidth={60}
                      />
                      <ErrorMessage name="code">
                        {msg => <FormHelperText>{msg}</FormHelperText>}
                      </ErrorMessage>
                    </FormControl>
                  )}
                />
                <br />
                <Button
                  variant="contained"
                  color="primary"
                  //   fullWidth
                  disabled={isSubmitting || Object.keys(errors).length > 0}
                  type="submit"
                >
                  Save
                </Button>{" "}
              </Form>
            )}
          </Formik>
        </Paper>
      )}
    </Container>
  )
}

export default AdminRep
