import React, { useState, useEffect } from "react"
import Button from "@material-ui/core/Button"
import TextField from "@material-ui/core/TextField"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"

import { getTowns, addTown, updateTown, addTownsBatch } from '../../state/store'
import { useSelector, useDispatch } from 'react-redux'
import firebase from 'firebase/app'
import XLSX from 'xlsx'

// const isBrowser = typeof window !== `undefined`

// let firebase = null
// if(isBrowser){
//   firebase = require("firebase/app")
//   require('firebase/firestore')
// }


const TownRow = ({ town, save }) => {
  const [currentTown, setCurrentTown] = useState(town)
  if (!currentTown.location) {
    setCurrentTown({ ...town, location: new firebase.firestore.GeoPoint(-26.205171, 28.049815) })
  }
  return (
    <TableRow>
      <TableCell component="th" scope="row">
        <TextField
          label="Name"
          margin="normal"
          variant="outlined"
          value={currentTown.name}
          onChange={e => {
            setCurrentTown({ ...currentTown, name: e.target.value })
          }}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          label="Latitude"
          margin="normal"
          variant="outlined"
          type="number"
          value={currentTown.location && currentTown.location.latitude}
          onChange={e => {
            setCurrentTown({ ...currentTown, location: new firebase.firestore.GeoPoint(parseFloat(e.target.value), currentTown.location.longitude) })
          }}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          label="Longitude"
          margin="normal"
          variant="outlined"
          type="number"
          value={currentTown.location && currentTown.location.longitude}
          onChange={e => {
            setCurrentTown({ ...currentTown, location: new firebase.firestore.GeoPoint(currentTown.location.latitude, parseFloat(e.target.value)) })
          }}
        />
      </TableCell>
      <TableCell align="right">
        <TextField
          label="Sun Hours"
          margin="normal"
          variant="outlined"
          value={currentTown.sunHours && currentTown.sunHours.toString()}
          onChange={e => {
            if (e.target.value.split(',').length !== 12) {
              // error
              console.log('error')
            } else {
              setCurrentTown({ ...currentTown, sunHours: e.target.value.split(',') })
            }
          }}
        />
      </TableCell>

      <TableCell align="right">
        {currentTown !== town &&
          <Button onClick={() => save({ ...currentTown, sunHours: currentTown.sunHours.map(a => parseFloat(a)) })}>Save</Button>}
      </TableCell>
    </TableRow>
  )
}

const AdminTowns = () => {
  const { towns } = useSelector(state => state.db)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getTowns())
  }, [])
  return (
    <>
      <Typography component="h1" variant="h5">
        Towns {towns && towns.data && towns.data.length}
      </Typography>
      {towns && towns.loading && <CircularProgress />}

      <Table aria-label="Add Town Table" style={{ overflowX: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>Add Town</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TownRow town={{ name: '', location: new firebase.firestore.GeoPoint(-29.3441277, 23.3631604), sunHours: [6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5, 6.5] }} save={(town) => {
            dispatch(addTown(town))
          }} />
        </TableBody>
      </Table>
      <Button onClick={() => dispatch(getTowns())}>Refresh</Button>

      {/* <Button
        color="primary"
        variant="contained"
        onClick={() => {

          let fixedData = []
          let top = []
          let name = []
          let location = []
          let sun0 = []
          let sun1 = []
          let sun2 = []
          let sun3 = []
          let sun4 = []
          let sun5 = []
          let sun6 = []
          let sun7 = []
          let sun8 = []
          let sun9 = []
          let sun10 = []
          let sun11 = []

          exported.sort((a, b) => a.name.localeCompare(b.name)).forEach(element => {
            top.push(element.id)
            name.push(element.name)
            location.push(`${element.location._latitude},${element.location._longitude}`)
            sun0.push(element.sunHours[0])
            sun1.push(element.sunHours[1])
            sun2.push(element.sunHours[2])
            sun3.push(element.sunHours[3])
            sun4.push(element.sunHours[4])
            sun5.push(element.sunHours[5])
            sun6.push(element.sunHours[6])
            sun7.push(element.sunHours[7])
            sun8.push(element.sunHours[8])
            sun9.push(element.sunHours[9])
            sun10.push(element.sunHours[10])
            sun11.push(element.sunHours[11])
          });

          fixedData = [[...top], [...name], [...location], [...sun0], [...sun1], [...sun2], [...sun3], [...sun4], [...sun5], [...sun6], [...sun7], [...sun8], [...sun9], [...sun10], [...sun11]]

          // const ws = XLSX.utils.json_to_sheet(exported, {header:["id", "name","sunHours"]});
          const ws = XLSX.utils.aoa_to_sheet(fixedData);

          // console.log(XLSX.utils.sheet_to_json(aWorkbook))

          const wb = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, "towns");

          XLSX.writeFile(wb, 'current_towns.xlsx');
        }
        }
      >Export Current Towns</Button> */}

      {/* <Button onClick={() => dispatch(addTownsBatch(exported.map(a=>(
        {id: a.id, name: a.name, location: {latitude: a.location._latitude, longitude: a.location._longitude}, sunHours: a.sunHours}
      ))))}>Batch Update</Button> */}

      <Table aria-label="Towns Table" style={{ overflowX: "auto" }}>
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell align="right">Lat</TableCell>
            <TableCell align="right">Long</TableCell>
            <TableCell align="right">Sun Hours</TableCell>
            <TableCell align="right">Update</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {towns && towns.data &&
            towns.data.map((row) => <TownRow key={row.id} town={row} save={(town) => {
              dispatch(updateTown(town))
            }} />
            )}
        </TableBody>
      </Table>
    </>
  )
}

export default AdminTowns
